<template>
  <div class="white">
    <v-toolbar>
      <v-slide-group class="mt-2">
        <v-slide-item class="mx-2">
          <v-select label="Mois" v-model="selected_month" :items="months" item-text="name"
                    item-value="value"></v-select>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <v-select label="Année" v-model="selected_year" :items="active_years"></v-select>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <site-tree-selector nomargin v-model="selected_site_tree"/>
        </v-slide-item>
        <v-slide-item>
          <v-btn color="primary" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>

    <v-card v-if="loaded" class="pa-2 white" id="stat-financier">
      <v-row>
        <v-col>
          <v-row align="center" justify="center">
            <v-col>
              <v-card @click="$router.push(`/finance?statut=previsionnel`)" color="cyan" class="white--text text-center pa-2">
                <span class="display-1">Budget maintenance<br>{{ total_previsionnel_per_year }} € HT</span>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col>
              <v-card @click="$router.push(`/finance?statut=realise`)" color="#D9B300" class="white--text text-center pa-2">
                <span class="display-1">Total réalisé<br>{{ total_realise_per_year }} € HT</span>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col>
              <v-card color="green" class="white--text text-center pa-2">
                <span class="display-1">Écart<br>{{ ecart_per_year }} € HT</span>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <apexchart type="bar" height="350" :options="realise_par_type.chartOptions"
                     :series="realise_par_type.series"></apexchart>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <apexchart type="pie" height="350" :options="realise_par_equipement_graph.chartOptions"
                     :series="realise_par_equipement_graph.series"></apexchart>
        </v-col>
        <v-col>
          <apexchart type="pie" height="350"
                     :options="realise_par_contrat_graph.chartOptions"
                     :series="realise_par_contrat_graph.series"></apexchart>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import dayjs from "dayjs";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import SiteTreeSelector from "@/views/SiteTreeSelector";

function roundDigits(value) {
  return Math.round(value * 100) / 100;
}

export default {
  name: 'StatFinancier',
  components: {SiteTreeSelector, "apexchart": VueApexCharts},
  mixins: [DateUtilMixin],
  async mounted() {
    await this.$store.dispatch("finance/fetchEntries");
    await this.$store.dispatch("assets/fetchAssets");
    await this.$store.dispatch("contracts/fetchContracts");
    await this.$store.dispatch("sites/fetchSites");
    this.loaded = true;
  },
  methods: {
    async printStats() {
      let statNode = document.querySelector("#stat-financier")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`financier${dayjs().format("DD-MM-YYYYTHH_mm")}.pdf`);
    },
    site_name(site) {
      let siteObject = site;
      if (siteObject === null) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    },
    showEntriesByType(event, context, config) {
        let typeDepenseIndex = config.dataPointIndex;
        if (typeDepenseIndex !== -1) {
          this.$router.push(`/finance/?type=${this.typesDepenseList[typeDepenseIndex]}`)
        }
      }
  },
  data: function () {
    return {
      loaded: false,
      typesDepenseList: ["Dépense ponctuelle", "Dépense sous contrat", "Dépense sous contrat supplémentaire", "Casse"],
    }
  },
  computed: {
    total_realise_per_year() {
      return this.$store.getters["finance/stats/total_realise_per_year"]
          .toLocaleString(undefined, {minimumFractionDigits: 2});
    },
    total_previsionnel_per_year() {
      return this.$store.getters["finance/stats/total_previsionnel_per_year"]
          .toLocaleString(undefined, {minimumFractionDigits: 2});
    },
    ecart_per_year() {
      return this.$store.getters["finance/stats/ecart_per_year"]
          .toLocaleString(undefined, {minimumFractionDigits: 2});
    },
    realise_par_type() {
      return {
        series: [{
          name: "Réalisé",
          data: [
            Math.round(this.$store.getters["finance/stats/total_realise_depense_ponctuelle"] * 100) / 100,
            Math.round(this.$store.getters["finance/stats/total_realise_sous_contrat"] * 100) / 100,
            Math.round(this.$store.getters["finance/stats/total_realise_sous_contrat_supplementaire"] * 100) / 100,
            Math.round(this.$store.getters["finance/stats/total_realise_casse"] * 100) / 100,
          ]
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            events: {
              click: this.showEntriesByType
            }
          },
          title: {
            text: "Réalisé par type de dépense",
            align: "center",
          },
          colors: ["#208774", "#E7B05C", "#706f6f"],
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " €"
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.typesDepenseList,
          }
        }
      }
    },
    realise_par_equipement_graph() {
      return {
        series: Object.values(this.realise_par_equipement).map(roundDigits),
        chartOptions: {
          chart: {
            type: 'pie',
            height: 350
          },
          title: {
            text: "Réalisé par équipement",
            align: "center",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " €"
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          labels: this.assets_labels,
        }
      }
    },
    realise_par_equipement() {
      return this.$store.getters["finance/stats/realise_par_equipement"];
    },
    assets_labels: function () {
      return Object.keys(this.realise_par_equipement).map((asset_id) => {
        let asset = this.assets.find((asset) => asset.id === parseInt(asset_id));
        return asset?.name ?? "Autres dépenses";
      })
    },
    realise_par_contrat_graph() {
      return {
        series: Object.values(this.realise_par_contrat).map(roundDigits),
        chartOptions: {
          chart: {
            type: 'pie',
            height: 350
          },
          title: {
            text: "Réalisé par contrat",
            align: "center",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " €"
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          labels: this.contracts_labels,
        }
      }
    },
    realise_par_contrat() {
      return this.$store.getters["finance/stats/realise_par_contrat"];
    },
    contracts_labels() {
      return Object.keys(this.realise_par_contrat).map((contract_id) => {
        let contract = this.contracts.find((contract) => contract.id === parseInt(contract_id));
        return contract?.name ?? "Équipement " + contract_id.toString();
      })
    },
    assets() {
      return this.$store.getters["assets/allAssets"];
    },
    contracts() {
      return this.$store.getters["contracts/allContracts"];
    },
    selected_year: {
      get() {
        return this.$store.getters["finance/stats/getSelectedYear"];
      },
      set(value) {
        return this.$store.commit("finance/stats/setSelectedYear", value);
      }
    },
    selected_month: {
      get() {
        return this.$store.getters["finance/stats/getSelectedMonth"];
      },
      set(value) {
        return this.$store.commit("finance/stats/setSelectedMonth", value);
      }
    },
    selected_site_tree: {
      get() {
        return this.$store.getters["finance/stats/getSelectedSiteTree"];
      },
      set(value) {
        return this.$store.commit("finance/stats/setSelectedSiteTree", value);
      }
    },
    sites() {
      return [
        {value: null, name: "Tous les sites"},
        ...this.$store.getters["sites/allSites"]
            .map(site => ({"name": this.site_name(site), "value": this.site_name(site)}))
      ];
    },
    active_years() {
      return this.$store.getters["finance/stats/active_years"];
    }
  },
}
</script>