<template>
  <div v-if="loaded">
    <v-toolbar>
      <v-slide-group>
        <v-slide-item>
          <v-select label="Contrat" class="mx-2" :items="contracts" v-model="selected_contract" item-text="name" item-value="id"></v-select>
        </v-slide-item>
        <v-slide-item>
          <v-select :items="active_years" v-model="selected_year_for_stats"></v-select>
        </v-slide-item>
        <v-slide-item>
          <v-btn color="primary" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-card id="stat-contrats">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="3"><h2 class="text-center primary--text">{{ selected_year_for_stats }}</h2></v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col v-if="selected_contract !== null" cols="3"><h2 class="text-center primary--text">{{ selected_contract.name }}</h2></v-col>
        <v-col v-else cols="3" class="text-center grey--text"><span>Veuillez choisir un contrat dans le menu déroulant.</span></v-col>
      </v-row>
      <v-row justify="center" justify-lg="space-around">
        <v-col cols="12" lg="5">
          <v-card class="white--text text-center display-1 pa-4" color="primary">
            <span>Total prévu du contrat <br> {{ sous_contrat_realise_par_contrat}} € HT</span>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5">
          <v-card class="text-center white--text display-1 pa-4" color="#D9B300">
            <span>Dépenses supplémentaires réalisés <br> {{ supplementaire_realise_par_contrat }} € HT</span>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <apexchart type="bar" height="350"
                     :series="realise_mensuel_par_contrat_graph.series"
                     :options="realise_mensuel_par_contrat_graph.chartOptions"></apexchart>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import * as dayjs from "dayjs";

export default {
  name: 'StatContrats',
  components: {"apexchart": VueApexCharts},
  data() {
    return {
      loaded: false
    }
  },
  async mounted() {
    if(this.$store.getters["finance/allEntries"].length === 0){
      await this.$store.dispatch("finance/fetchEntries");
    }
    if(this.$store.getters["contracts/allContracts"].length === 0){
      await this.$store.dispatch("contracts/fetchContracts");
    }
    this.loaded = true;
  },
  computed: {
    sous_contrat_realise_par_contrat() {
      return this.$store.getters["contracts/stats/sous_contrat_realise_mensuel_par_contrat"].reduce((a, b) => a + b)
          .toLocaleString(undefined, {minimumFractionDigits: 2});
    },
    supplementaire_realise_par_contrat() {
      return this.$store.getters["contracts/stats/supplementaire_realise_mensuel_par_contrat"].reduce((a, b) => a + b)
          .toLocaleString(undefined, {minimumFractionDigits: 2});
    },
    active_years() {
      return this.$store.getters['finance/stats/active_years'];
    },
    selected_contract: {
      get() {
        let contract_id = this.$store.getters["contracts/stats/contract"];
        return contract_id != undefined ? this.$store.getters["contracts/allContracts"].find(contract => contract.id === contract_id) : null;
      },
      set(value) {
        return this.$store.commit("contracts/stats/setContract", value);
      }
    },
    contracts() {
      return this.$store.getters["contracts/allContracts"];
    },
    selected_year_for_stats: {
      get() {
        return this.$store.getters["contracts/stats/year"];
      },
      set(value) {
        return this.$store.commit("contracts/stats/setYear", value);
      }
    },
    realise_mensuel_par_contrat_graph() {
      return {
        series: [{
          name: 'Sous contrat',
          data: this.$store.getters["contracts/stats/sous_contrat_realise_mensuel_par_contrat"],
        }, {
          name: 'Sous contrat supplémentaire',
          data: this.$store.getters["contracts/stats/supplementaire_realise_mensuel_par_contrat"],
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
          },
          yaxis: {
            title: {
              text: 'Réalisé'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " €";
              }
            }
          }
        },
      }
    },
  },
  methods: {
    async printStats() {
      let statNode = document.querySelector("#stat-contrats")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`contrats${dayjs().format("DD-MM-YYYYTHH_mm")}.pdf`);
    }
  }
}
</script>