<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>Équipements</v-toolbar-title>
      <v-slide-group>
        <v-slide-item class="mx-2">
          <SiteTreeSelector v-model="selected_site_tree" nomargin/>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <v-btn color="primary" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-card v-if="loaded" id="stat-equipements">
      <v-row>
        <v-col cols="6">
          <apexchart type="pie" height="350"
                     :options="realise_par_categorie_graph.chartOptions"
                     :series="realise_par_categorie_graph.series"></apexchart>
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="stats_equipement">
            <template v-slot:item.asset_id="{item}">
              <span>{{getAssetName(item.asset_id)}}</span>
            </template>
            <template v-slot:item.asset_category="{item}">
              <span>{{getAssetCategory(item.asset_id)}}</span>
            </template>
            <template v-slot:item.total_realise="{item}">
              <span>{{item.total_realise}} € HT</span>
            </template>
            <template v-slot:item.cout_achat="{item}">
              <span>{{getAssetCost(item.asset_id)}} € HT</span>
            </template>
            <template v-slot:item.valeur_comptable="{item}">
              <span>{{getAssetValue(item.asset_id)}} € HT</span>
            </template>
            <template v-slot:item.solde_amortissement="{item}">
              <span>{{getAssetSolde(item.asset_id)}} € HT</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import dayjs from "dayjs";
import VueApexCharts from "vue-apexcharts";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import SiteTreeSelector from "@/views/SiteTreeSelector";

export default {
  name: 'TabEquipements',
   components: {SiteTreeSelector, "apexchart": VueApexCharts},
  async mounted() {
    if (this.$store.getters["assets/allAssets"].length === 0) {
      await this.$store.dispatch("assets/fetchAssets");
    }
    if (this.$store.getters["interventions/interventions"].length === 0) {
      await this.$store.dispatch("interventions/fetchInterventions");
    }
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      headers: [
        {"value": "asset_id", "text": "Équipement"},
          {"value": "asset_category", "text": "Domaine"},
        {"value": "nombre_interventions", "text": "Nombre d'interventions"},
        {"value": "total_realise", "text": "Total des dépenses"},
        {"value": "cout_achat", "text": "Coût d'achat"},
        {"value": "valeur_comptable", "text": "Valeur comptable"},
        {"value": "solde_amortissement", "text": "Solde amortissement"},
      ]
    }
  },
  computed: {
    selected_site_tree:{
      get() {
        return this.$store.getters["assets/stats/getSelectedSiteTree"];
      },
      set(value) {
        this.$store.commit("assets/stats/setSelectedSiteTree", value);
      }
    },
    stats_equipement() {
      return this.$store.getters["assets/stats/stats_equipement"];
    },
    realise_par_categorie() {
      return this.$store.getters["assets/stats/realise_par_categorie"];
    },
    realise_par_categorie_graph() {
      return {
        series: Object.values(this.realise_par_categorie),
        chartOptions: {
          chart: {
            type: 'pie',
            height: 350
          },
          title: {
            text: "Réalisé par domaine",
            align: "center",
          },
          tooltip: {
              y: {
                formatter: function (val) {
                  return val + " €"
                }
              }
            },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          labels: Object.keys(this.realise_par_categorie),
        }
      }
    },

  },
  methods: {
    getAssetName(asset_id) {
      let asset = this.$store.getters["assets/allAssets"].find(asset => asset.id === parseInt(asset_id));
      return asset !== null ? asset?.name : "Équipement inconnu";
    },
    getAssetCost(asset_id) {
      let asset = this.$store.getters["assets/allAssets"].find(asset => asset.id === parseInt(asset_id));
      return asset !== null && asset?.cout_achat != null ? asset.cout_achat : 0;
    },
    getAsset(asset_id) {
      return this.$store.getters["assets/allAssets"].find(asset => asset.id === parseInt(asset_id));
    },
    getAssetCategory(asset_id) {
      let asset = this.getAsset(asset_id);
      return asset?.category?.name || "Sans catégorie";
    },
    getAssetValue(asset_id) {
     let asset = this.$store.getters["assets/allAssets"].find(asset => asset.id === parseInt(asset_id));
     if (asset?.cout_achat !== null && asset?.date_achat !== null && asset?.amortissement !== null) {
       let cout_annuel = parseFloat(asset.cout_achat) / parseInt(asset.amortissement);
       let annees_ecoules = dayjs().year() - dayjs(asset.date_achat).year() + 1;
       let valeur_comptable = parseFloat(asset.cout_achat) - (annees_ecoules * cout_annuel);
       valeur_comptable = valeur_comptable < 0 ? 0 : valeur_comptable;
       return Math.round(valeur_comptable * 100) / 100;
     } else {
       return 0;
     }
    },
    getAssetSolde(asset_id) {
      return this.getAssetCost(asset_id) - this.getAssetValue(asset_id);
    },
    async printStats() {
      let statNode = document.querySelector("#stat-equipements")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`equipements${dayjs().format("DD-MM-YYYYTHH_MM")}.pdf`);
    }
  }
}
</script>